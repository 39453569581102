import {library} from '@fortawesome/fontawesome-svg-core';
import {faAddressCard as fadAddressCard} from '@fortawesome/pro-duotone-svg-icons/faAddressCard';
import {faBan as fadBan} from '@fortawesome/pro-duotone-svg-icons/faBan';
import {faCalendarAlt as fadCalendarAlt} from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';
import {faClinicMedical} from '@fortawesome/pro-duotone-svg-icons/faClinicMedical';
import {faFileAlt as fadFileAlt} from '@fortawesome/pro-duotone-svg-icons/faFileAlt';
import {faInfoCircle as fadInfoCircle} from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import {faUserClock} from '@fortawesome/pro-duotone-svg-icons/faUserClock';
import {faUserMd as fadUserMd} from '@fortawesome/pro-duotone-svg-icons/faUserMd';
import {faUserPlus as fadUserPlus} from '@fortawesome/pro-duotone-svg-icons/faUserPlus';
import {faUsersClass as fadUsersClass} from '@fortawesome/pro-duotone-svg-icons/faUsersClass';
import {faUsersMedical} from '@fortawesome/pro-duotone-svg-icons/faUsersMedical';
import {faCheckSquare as falCheckSquare} from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import {faEnvelope as falEnvelope} from '@fortawesome/pro-light-svg-icons/faEnvelope';
import {faSquare as falSquare} from '@fortawesome/pro-light-svg-icons/faSquare';
import {faCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import {faCheckSquare} from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import {faClipboard} from '@fortawesome/pro-regular-svg-icons/faClipboard';
import {faInboxOut} from '@fortawesome/pro-regular-svg-icons/faInboxOut';
import {faMinusSquare} from '@fortawesome/pro-regular-svg-icons/faMinusSquare';
import {faSquare} from '@fortawesome/pro-regular-svg-icons/faSquare';
import {faUserCircle} from '@fortawesome/pro-regular-svg-icons/faUserCircle';
import {faAddressCard} from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import {faAngleDoubleLeft} from '@fortawesome/pro-solid-svg-icons/faAngleDoubleLeft';
import {faAngleDoubleRight} from '@fortawesome/pro-solid-svg-icons/faAngleDoubleRight';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import {faAngleLeft} from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import {faAngleRight} from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import {faBan as fasBan} from '@fortawesome/pro-solid-svg-icons/faBan';
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars';
import {faBooksMedical} from '@fortawesome/pro-solid-svg-icons/faBooksMedical';
import {faCalendarAlt} from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import {faCalendarCheck} from '@fortawesome/pro-solid-svg-icons/faCalendarCheck';
import {faCamera} from '@fortawesome/pro-solid-svg-icons/faCamera';
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck';
import {faCheckCircle as fasCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faCircle} from '@fortawesome/pro-solid-svg-icons/faCircle';
import {faClock} from '@fortawesome/pro-solid-svg-icons/faClock';
import {faCloudUpload} from '@fortawesome/pro-solid-svg-icons/faCloudUpload';
import {faCreditCard} from '@fortawesome/pro-solid-svg-icons/faCreditCard';
import {faDoorClosed} from '@fortawesome/pro-solid-svg-icons/faDoorClosed';
import {faDoorOpen} from '@fortawesome/pro-solid-svg-icons/faDoorOpen';
import {faDownload} from '@fortawesome/pro-solid-svg-icons/faDownload';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import {faExclamationSquare} from '@fortawesome/pro-solid-svg-icons/faExclamationSquare';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import {faEye} from '@fortawesome/pro-solid-svg-icons/faEye';
import {faFile} from '@fortawesome/pro-solid-svg-icons/faFile';
import {faFileCirclePlus} from '@fortawesome/pro-solid-svg-icons/faFileCirclePlus';
import {faFileAlt} from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import {faFileContract} from '@fortawesome/pro-solid-svg-icons/faFileContract';
import {faFileExclamation as fasFileExclamation} from '@fortawesome/pro-solid-svg-icons/faFileExclamation';
import {faFileMedicalAlt} from '@fortawesome/pro-solid-svg-icons/faFileMedicalAlt';
import {faFilePdf} from '@fortawesome/pro-solid-svg-icons/faFilePdf';
import {faFirstAid} from '@fortawesome/pro-solid-svg-icons/faFirstAid';
import {faFlag} from '@fortawesome/pro-solid-svg-icons/faFlag';
import {faFolder} from '@fortawesome/pro-solid-svg-icons/faFolder';
import {faFolders} from '@fortawesome/pro-solid-svg-icons/faFolders';
import {faHandHoldingMedical} from '@fortawesome/pro-solid-svg-icons/faHandHoldingMedical';
import {faHourglassEnd} from '@fortawesome/pro-solid-svg-icons/faHourglassEnd';
import {faImage} from '@fortawesome/pro-solid-svg-icons/faImage';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {faList} from '@fortawesome/pro-solid-svg-icons/faList';
import {faLock} from '@fortawesome/pro-solid-svg-icons/faLock';
import {faMicrophone} from '@fortawesome/pro-solid-svg-icons/faMicrophone';
import {faMicrophoneSlash} from '@fortawesome/pro-solid-svg-icons/faMicrophoneSlash';
import {faMinus} from '@fortawesome/pro-solid-svg-icons/faMinus';
import {faNotesMedical} from '@fortawesome/pro-solid-svg-icons/faNotesMedical';
import {faPassport} from '@fortawesome/pro-solid-svg-icons/faPassport';
import {faPause} from '@fortawesome/pro-solid-svg-icons/faPause';
import {faPen} from '@fortawesome/pro-solid-svg-icons/faPen';
import {faPencil} from '@fortawesome/pro-solid-svg-icons/faPencil';
import {faPhone} from '@fortawesome/pro-solid-svg-icons/faPhone';
import {faPlug} from '@fortawesome/pro-solid-svg-icons/faPlug';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faPlusCircle} from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import {faPrint} from '@fortawesome/pro-solid-svg-icons/faPrint';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons/faQuestion';
import {faQuestionCircle} from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import {faRedo} from '@fortawesome/pro-solid-svg-icons/faRedo';
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch';
import {faShare} from '@fortawesome/pro-solid-svg-icons/faShare';
import {faShareAlt} from '@fortawesome/pro-solid-svg-icons/faShareAlt';
import {faSignOut} from '@fortawesome/pro-solid-svg-icons/faSignOut';
import {faSlidersH} from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import {faSort} from '@fortawesome/pro-solid-svg-icons/faSort';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons/faSpinner';
import {faSquare as fasSquare} from '@fortawesome/pro-solid-svg-icons/faSquare';
import {faStickyNote} from '@fortawesome/pro-solid-svg-icons/faStickyNote';
import {faSync} from '@fortawesome/pro-solid-svg-icons/faSync';
import {faTh} from '@fortawesome/pro-solid-svg-icons/faTh';
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes';
import {faTimesCircle} from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import {faToggleOn} from '@fortawesome/pro-solid-svg-icons/faToggleOn';
import {faTrash} from '@fortawesome/pro-solid-svg-icons/faTrash';
import {faTrashAlt} from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faUserFriends} from '@fortawesome/pro-solid-svg-icons/faUserFriends';
import {faUserMd} from '@fortawesome/pro-solid-svg-icons/faUserMd';
import {faUserPlus} from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import {faUsers} from '@fortawesome/pro-solid-svg-icons/faUsers';
import {faUsersClass} from '@fortawesome/pro-solid-svg-icons/faUsersClass';
import {faUsersCog as fasUsersCog} from '@fortawesome/pro-solid-svg-icons/faUsersCog';
import {faVideo} from '@fortawesome/pro-solid-svg-icons/faVideo';
import {faVideoSlash} from '@fortawesome/pro-solid-svg-icons/faVideoSlash';

library.add(
  faAddressCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBars,
  faBooksMedical,
  faCalendarAlt,
  faCalendarCheck,
  faCamera,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClinicMedical,
  faClipboard,
  faClock,
  faCloudUpload,
  faCreditCard,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faExclamationCircle,
  faExclamationSquare,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileAlt,
  faFileCirclePlus,
  faFileContract,
  faFileMedicalAlt,
  faFilePdf,
  faFirstAid,
  faFlag,
  faFolder,
  faFolders,
  faHandHoldingMedical,
  faHourglassEnd,
  faImage,
  faInboxOut,
  faInfoCircle,
  faList,
  faLock,
  faMicrophone,
  faMicrophoneSlash,
  faMinus,
  faMinusSquare,
  faNotesMedical,
  faPassport,
  faPause,
  faPen,
  faPencil,
  faPhone,
  faPlug,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faSearch,
  faShare,
  faShareAlt,
  faSignOut,
  faSlidersH,
  faSpinner,
  faSquare,
  faStickyNote,
  faSync,
  faTh,
  faTimes,
  faTimesCircle,
  faToggleOn,
  faTrash,
  faTrashAlt,
  faUser,
  faUserCircle,
  faUserClock,
  faUserFriends,
  faUserMd,
  faUserPlus,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUsersMedical,
  faVideo,
  faVideoSlash,
  fadAddressCard,
  fadBan,
  fadCalendarAlt,
  fadFileAlt,
  fadInfoCircle,
  fadUserMd,
  fadUserPlus,
  fadUsersClass,
  falCheckSquare,
  falEnvelope,
  falSquare,
  fasBan,
  fasCheckCircle,
  fasFileExclamation,
  fasSquare,
  fasUsersCog,
  faSort,
);
