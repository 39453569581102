import React, {ReactNode} from 'react';
import {
  makeStyles,
  useTheme,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core';

type Props = {
  children: ReactNode | null;
  style?: Object;
  hidden?: boolean;
  variant?: HeadingVariant;
  color?: HeadingColor;
  weight?: HeadingWeight;
};

type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h6' | 'subtitle2';
type HeadingColor = 'primary' | 'secondary';
type HeadingWeight = 'bold' | 'light';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 700,
      marginBottom: '2rem',
    },
  }),
);
export function MuiHeading({
  children,
  style = {},
  hidden,
  variant = 'h2',
  color,
  weight,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {!hidden && (
        <Typography
          variant={variant}
          className={classes.heading}
          style={{
            color:
              color === 'primary'
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
            fontWeight: weight === 'light' ? 400 : 700,
            ...style,
          }}
        >
          {children}
        </Typography>
      )}
    </>
  );
}
