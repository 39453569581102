export const h1 = '2.75em';
export const h2 = '2em';
export const h3 = '1.375em';
export const h4 = '1em';
export const instruction = '0.75em';
export const body = '1em';

export const sansSerif = "'Roboto', 'Helvetica', sans-serif";
export const heading = "'Roboto-Bold', 'Helvetica', sans-serif";
export const cursive = "'Always Forever', Calibri, sans-serif";
export const serif =
  "'Georgia-local', Georgia, Times, 'Times New Roman', serif";
