import {getTheme} from '@src/theme/muiTheme';

const greyPalette = {
  '00': '#000000',
  '10': '#0f0f0f',
  '20': '#242424',
  '30': '#3a3a3a',
  '40': '#515151',
  '50': '#696969',
  '60': '#828282',
  '70': '#9c9c9c',
  '80': '#b7b7b7',
  '90': '#efefef',
  '100': '#ffffff',
};

const muiTheme = getTheme();

export const primaryText = muiTheme.palette.primary.contrastText;
export const primaryDark = muiTheme.palette.primary.dark;
export const primary = muiTheme.palette.primary.main;
export const primaryLight = muiTheme.palette.primary.light;

export const backdrop = muiTheme.palette.background.default;

export const danger = muiTheme.palette.error.main;
export const dangerDark = muiTheme.palette.error.dark;
export const dangerLightest = muiTheme.palette.error.light;

export const disabledText = '#959595';
export const disabledBg = '#dddddd';

export const error = muiTheme.palette.error.main;
export const errorLight = muiTheme.palette.error.light;
export const errorText = muiTheme.palette.error.contrastText;

export const success = muiTheme.palette.success.main;
export const successLight = muiTheme.palette.success.light;
export const successText = muiTheme.palette.success.contrastText;

export const info = muiTheme.palette.info.main;
export const infoLightest = muiTheme.palette.info.light;

export const lightestGray = greyPalette[100];
export const lighterGrey = greyPalette[90];
export const lightGrey = greyPalette[80];
export const grey = greyPalette[60];

export const secondaryText = muiTheme.palette.secondary.contrastText;
export const secondary = muiTheme.palette.secondary.main;

export const warning = muiTheme.palette.warning.main;
