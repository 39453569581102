/**
 * This is all based on Auth0's rule sets given here: https://auth0.com/docs/connections/database/password-strength?_ga=2.210355859.740598816.1591397280-1634894219.1584552954
 * Those are based on OWASP: https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/Authentication_Cheat_Sheet.md
 */

export const PolicyLevels = [
  'none',
  'low',
  'fair',
  'good',
  'excellent',
] as const;

export type PolicyLevel = typeof PolicyLevels[number];

export const isPolicyLevel = (r: PolicyLevel | string): r is PolicyLevel =>
  PolicyLevels.includes(r as PolicyLevel);

type PasswordRule = {
  description: string[];
  passes: (pwd: string) => boolean;
};

export type PasswordRules = PasswordRule[];

const meetsCharacterLimit = (limit: number): PasswordRule => ({
  description: [`Has at least ${limit} character${limit === 1 ? '' : 's'}`],
  passes: pwd => pwd.length >= limit,
});

type CharClass = {
  name: string;
  description: string;
  pattern: RegExp;
};
const LOWER = {
  name: 'lower',
  description: 'lower-case character (a-z)',
  pattern: /[a-z]/,
};
const UPPER = {
  name: 'upper',
  description: 'upper-case character (A-Z)',
  pattern: /[A-Z]/,
};
const NUMBER = {name: 'number', description: 'number (0-9)', pattern: /[0-9]/};
const SPECIAL = {
  name: 'special',
  description: 'special character (!@#$%^&*)',
  pattern: /[!@#$%^&*]/,
};

const hasPattern = (x: CharClass): PasswordRule => ({
  description: [`Has at least one ${x.description}`],
  passes: pwd => x.pattern.test(pwd),
});

const hasLowerCase = (): PasswordRule => hasPattern(LOWER);
const hasUpperCase = (): PasswordRule => hasPattern(UPPER);
const hasNumber = (): PasswordRule => hasPattern(NUMBER);

const hasMaxConsecutive = (maxCons: number): PasswordRule => {
  const regex = new RegExp(`(.)${'\\1'.repeat(Math.max(maxCons - 1, 0))}`);
  return {
    description: [`Has no more than ${maxCons} identical characters in a row`],
    passes: (p: string) => !regex.test(p),
  };
};

/*
 * We use spread here, because split is not unicode safe.
 * It's not a huge stretch that some smarty-pants decides to use an emoji in their
 * password.
 */
const analyzePassword = (p: string, classes: CharClass[]) => {
  const initial: {[className: string]: boolean} = classes
    .map(c => c.name)
    .reduce(
      (i, c) => ({
        ...i,
        [c]: false,
      }),
      {},
    );
  return [...p].reduce((analysis, char) => {
    const a = {...analysis};
    classes.forEach(c => {
      a[c.name] = a[c.name] || c.pattern.test(p);
    });
    return a;
  }, initial);
};

const hasRulesSpread = (
  typeCount: number,
  classes: CharClass[],
): PasswordRule => ({
  description: [
    `Has at least ${typeCount} of each of the character types`,
    ...classes.map(c => c.description),
  ],
  passes: pwd => {
    const analysis = analyzePassword(pwd, classes);
    return Object.values(analysis).filter(Boolean).length >= typeCount;
  },
});

export const RuleSets: Record<PolicyLevel, PasswordRules> = {
  none: [meetsCharacterLimit(1)],
  low: [meetsCharacterLimit(6)],
  fair: [meetsCharacterLimit(8), hasLowerCase(), hasUpperCase(), hasNumber()],
  good: [
    meetsCharacterLimit(8),
    hasRulesSpread(3, [LOWER, UPPER, NUMBER, SPECIAL]),
  ],
  excellent: [
    meetsCharacterLimit(10),
    hasRulesSpread(3, [LOWER, UPPER, NUMBER, SPECIAL]),
    hasMaxConsecutive(2),
  ],
};
