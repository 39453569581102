import {
  isPolicyLevel,
  PasswordRules,
  PolicyLevel,
  RuleSets,
} from './util/auth/passwordRules';

type AppConfig = {
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_ADMIN_CLIENT_ID: string;
  API_URL: string;
  STRIPE_PUBLIC_KEY: string;
  STREAMER_PORT: string;
  UPLOAD_URL: string;
  RAVEN_DSN: string;
  OPTIMIZELY_KEY: string;
  PASSWORD_RULESET: PolicyLevel;
};

type Environment = 'local' | 'staging' | 'production' | 'training';

export type ReleaseInfo = {
  NODE_ENV: string;
  RELEASE: string;
  RAVEN_ENVIRONMENT: Environment;
  USER_TIMEOUT: number;
  GA_TRACKING_ID: string;
  maintenanceModeStart?: Date;
};

type AppConfigKey = keyof AppConfig;

const instances: {[env in Environment]?: ConfigService} = {};

export default class ConfigService {
  configObject: Promise<AppConfig>;

  static setCustomerCode(code: string) {
    console.debug(`Setting 'source' code: ${code}`);
    window.localStorage.setItem('source', code);
  }

  static getCustomerCode() {
    return window.localStorage.getItem('source');
  }

  static clearCustomerCode() {
    console.debug("Clearing 'source' code");
    window.localStorage.removeItem('source');
  }

  static setLogoutDestination(logoutDestination: string) {
    console.debug(`Setting 'logoutDestination': ${logoutDestination}`);
    window.localStorage.setItem('logoutDestination', logoutDestination);
  }

  static getLogoutDestination() {
    return window.localStorage.getItem('logoutDestination');
  }

  static clearLogoutDestination() {
    console.debug("Clearing 'logoutDestination'");
    window.localStorage.removeItem('logoutDestination');
  }

  static isAdminSite() {
    return window.location.hostname.startsWith('admin');
  }

  static environmentIs(...env: Environment[]) {
    const releaseInfo = ConfigService.getReleaseInfo();
    return env.includes(releaseInfo.RAVEN_ENVIRONMENT);
  }

  public static getBaseDomain() {
    const {hostname} = window.location;
    if (hostname.endsWith('ahana.health')) {
      return 'ahana.health';
    }
    if (hostname.endsWith('refyneconnectedcare.vynemedical.com')) {
      return 'refyneconnectedcare.vynemedical.com';
    }
    console.warn(`Could not determine base domain from ${hostname}`);
    return 'ahana.health';
  }

  static getSupportEmail() {
    const baseDomain = this.getBaseDomain();
    return `support@${baseDomain}`;
  }

  static getDomain(): string {
    return ConfigService.isAdminSite()
      ? ConfigService.getAdminDomain()
      : ConfigService.getAppDomain();
  }

  static getAppDomain(): string {
    const baseDomain = this.getBaseDomain();
    const domains: Record<Environment, string> = {
      local: `https://app.local.${baseDomain}:8443`,
      staging: `https://app.staging.${baseDomain}`,
      production: `https://app.${baseDomain}`,
      training: `https://app.training.${baseDomain}`,
    };

    const releaseInfo = ConfigService.getReleaseInfo();
    return domains[releaseInfo.RAVEN_ENVIRONMENT];
  }

  static getAdminDomain(): string {
    const baseDomain = this.getBaseDomain();
    const domains: Record<Environment, string> = {
      local: `https://admin.local.${baseDomain}:8443`,
      staging: `https://admin.staging.${baseDomain}`,
      production: `https://admin.${baseDomain}`,
      training: `https://admin.training.${baseDomain}`,
    };

    const releaseInfo = ConfigService.getReleaseInfo();
    return domains[releaseInfo.RAVEN_ENVIRONMENT];
  }

  static getReleaseInfo(): ReleaseInfo {
    if (!window.ReleaseInfo) {
      throw new Error('No ReleaseInfo available');
    }
    return window.ReleaseInfo;
  }

  static getAppVersion(): string {
    return window.APP_VERSION ?? 'Unknown';
  }

  static getEnvironmentInstance() {
    const releaseInfo = ConfigService.getReleaseInfo();
    return ConfigService.getServiceForEnvironment(
      releaseInfo.RAVEN_ENVIRONMENT,
    );
  }

  static getServiceForEnvironment(environment: Environment): ConfigService {
    if (!instances[environment]) {
      instances[environment] = new ConfigService(environment);
    }

    return instances[environment] as ConfigService;
  }

  constructor(environment: string) {
    this.configObject = fetch(
      `${process.env.CONFIG_URL || ''}/${environment}.json`,
    ).then(response => response.json());
  }

  get(key: AppConfigKey): Promise<AppConfig[AppConfigKey] | undefined>;
  get(
    key: AppConfigKey,
    fallback: AppConfig[AppConfigKey],
  ): Promise<AppConfig[AppConfigKey]>;
  get(
    key: AppConfigKey,
    fallback?: AppConfig[AppConfigKey],
  ): Promise<AppConfig[AppConfigKey] | undefined> {
    return this.configObject.then(c => c[key] ?? fallback);
  }

  async getPasswordRules(): Promise<PasswordRules> {
    const policyLevel = await this.get('PASSWORD_RULESET', 'excellent');
    if (!isPolicyLevel(policyLevel)) {
      return RuleSets.excellent;
    }
    return RuleSets[policyLevel];
  }

  async getEventsHostname(): Promise<string> {
    const baseDomain = ConfigService.getBaseDomain();
    const streamerPort = await this.get('STREAMER_PORT');
    const domains: Record<Environment, string> = {
      local: `events.local.${baseDomain}`,
      staging: `events.staging.${baseDomain}`,
      production: `events.${baseDomain}`,
      training: `events.training.${baseDomain}`,
    };

    const releaseInfo = ConfigService.getReleaseInfo();
    return `${domains[releaseInfo.RAVEN_ENVIRONMENT]}${
      streamerPort === '' ? '' : `:${streamerPort}`
    }`;
  }
}
