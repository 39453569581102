import React, {ReactNode} from 'react';
import {makeStyles, createStyles, Typography, Theme} from '@material-ui/core';

type ParagraphTextVariant = 'body1' | 'body2';

type Props = {
  children: ReactNode | null;
  style?: Object;
  hidden?: boolean;
  className?: string;
  variant?: ParagraphTextVariant;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionsText: {
      color: theme.palette.common.black,
    },
  }),
);

export function ParagraphText({
  children,
  style,
  hidden,
  variant = 'body1',
  className,
}: Props) {
  const classes = useStyles();

  if (hidden) return null;

  return (
    <Typography
      variant={variant}
      paragraph
      className={`${classes.instructionsText} ${className}`}
      style={style}
    >
      {children}
    </Typography>
  );
}
