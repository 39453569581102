import {CSSProperties} from '@material-ui/styles';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@material-ui/core/styles';
import ConfigService from '@src/ConfigService';

const ahanaTheme: ThemeOptions = {
  palette: {
    primary: {main: '#5759fb'},
    secondary: {main: '#202a58'},
    error: {main: '#DD2727'},
    warning: {main: '#f1b100'},
    background: {
      default: '#F2F2F2',
    },
  },
  typography: {
    fontFamily: "'Roboto'",
    fontSize: 16,
    fontWeightRegular: 400,
    body1: {
      lineHeight: '1.5',
      letterSpacing: 0.5,
    },
    h4: {
      fontSize: 20,
    },
    h3: {
      fontSize: 24,
    },
    h2: {
      fontSize: 32,
    },
    h1: {
      fontSize: 48,
    },
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#DD2727',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Roboto' as CSSProperties['@font-face'],
      },
    },
  },
};

const refyneTheme: ThemeOptions = {
  palette: {
    primary: {main: '#3F2A56'},
    secondary: {main: '#A8DDE9', contrastText: '#3F2A56'},
    error: {main: '#DD2727'},
    warning: {main: '#f1b100'},
    background: {
      default: '#F2F2F2',
    },
  },
  typography: {
    fontFamily: "'Roboto'",
    fontSize: 16,
    fontWeightRegular: 400,
    body1: {
      fontLetterSpacing: 0.5,
      lineHeight: '1.5',
    },
    h4: {
      fontSize: 20,
    },
    h3: {
      fontSize: 24,
    },
    h2: {
      fontSize: 32,
    },
    h1: {
      fontSize: 48,
    },
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#DD2727',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Roboto' as CSSProperties['@font-face'],
      },
    },
  },
};

export const getTheme = () => {
  const domain = ConfigService.getBaseDomain();
  const themeOptions = domain === 'ahana.health' ? ahanaTheme : refyneTheme;

  return responsiveFontSizes(createMuiTheme(themeOptions));
};
