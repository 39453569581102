import 'custom-event-polyfill';
import 'nodelist-foreach-polyfill';
import 'ts-polyfill/lib/es2015-collection';
import 'ts-polyfill/lib/es2015-core';
import 'ts-polyfill/lib/es2015-iterable';
import 'ts-polyfill/lib/es2015-promise';
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-string';
import 'ts-polyfill/lib/es2018-promise';
import 'ts-polyfill/lib/es2019-array';
import 'url-search-params-polyfill';
